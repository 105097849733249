import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBars, faWallet } from '@fortawesome/free-solid-svg-icons';
import './Header.css'; // Make sure the path is correct

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="header">
      <div className="social-media-icons">
        <FontAwesomeIcon icon={faTwitter} className="icon" />
        <FontAwesomeIcon icon={faFacebookF} className="icon" />
        <FontAwesomeIcon icon={faInstagram} className="icon" />
      </div>
      
      <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
        <a href="#about">About</a>
        <a href="#tokenomics">Tokenomics</a>
        <a href="#music">Music Box</a>
        <a href="#features">Features</a>
        <a href="#faq">FAQ</a>
        <a href="#mintnft">Mint NFT</a>
      </nav>

      <div className="wallet-connect-button">
        <button className="button">
          <FontAwesomeIcon icon={faWallet} className="icon" />
          <span>Connect Wallet</span>
        </button>
      </div>

      <div className="burger-menu" onClick={toggleMenu}>
        <FontAwesomeIcon icon={faBars} className="icon" />
      </div>
    </header>
  );
};

export default Header;

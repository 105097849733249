import React, { useState, useRef, useEffect } from 'react';
import './Musicplayer.css';
import logoImage from './logo2.png'; // Update the path as needed

const Musicplayer = () => {
  const songs = [
    { name: "eGirl AI -  she's so fly (she's so fly)", url: "/music/song1.mp3" },
    { name: "eGirl AI - I'm lazy, make me rich eGirl AI", url: "/music/song2.mp3" },
    { name: "eGirl AI - Take a shower", url: "/music/song3.mp3" },
  ];

  const [playing, setPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [volume, setVolume] = useState(0.4); // Set initial volume to 40%
  const audioRef = useRef(null);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);

// eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.4; // Set volume to 40%
    }

    const handleFirstInteraction = () => {
      playSong(songs[0].url);
      window.removeEventListener('click', handleFirstInteraction);
      window.removeEventListener('keydown', handleFirstInteraction);
    };

    window.addEventListener('click', handleFirstInteraction);
    window.addEventListener('keydown', handleFirstInteraction);

    return () => {
      window.removeEventListener('click', handleFirstInteraction);
      window.removeEventListener('keydown', handleFirstInteraction);
    };
  }, []);

  const playSong = (songUrl) => {
    setPlaying(true);
    audioRef.current.src = songUrl;
    audioRef.current.load();
    audioRef.current.play().catch(error => {
      console.error("Error attempting to play audio:", error);
    });
  };



  // Function to toggle play/pause
  const togglePlayPause = () => {
    if (audioRef.current) {
      if (playing) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setPlaying(!playing);
    }
  };

  // Function to handle volume change
  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  // Function to handle progress bar change
  const handleTimeChange = (e) => {
    const newTime = e.target.value;
    setCurrentTime(newTime);
    audioRef.current.currentTime = newTime;
    if (!playing) {
      setPlaying(true);
      audioRef.current.play();
    }
  };

// Function to update the current time in the state as the song plays
const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  
  // Function to play next song
  const playNextSong = () => {
    let nextIndex = currentSongIndex + 1;
    if (nextIndex >= songs.length) {
      nextIndex = 0; // Loop back to the first song if it's the end of the list
    }
    setCurrentSongIndex(nextIndex);
    playSong(songs[nextIndex].url);
  };

  // Function to play previous song
  const playPreviousSong = () => {
    let prevIndex = currentSongIndex - 1;
    if (prevIndex < 0) {
      prevIndex = songs.length - 1; // Loop to the last song if it's the start of the list
    }
    setCurrentSongIndex(prevIndex);
    playSong(songs[prevIndex].url);
  };


  return (
    <section className="aboutmusic" id="music"> 
    <section className="aboutmusic">
      <div className="aboutmusic-content">
        <h1>eGirl Music Mix</h1>
        <img src={logoImage} alt="About Us" style={{ maxWidth: '400px' }}/>
        <p>Discover the ultimate gaming mix with eGirl. Press play and level up your game!</p>
        <div className="audio-controls">
          <div className="playback-controls">
            <button className="button" onClick={togglePlayPause}>
              {playing ? 'Pause' : 'Play'}
            </button>
          </div>
          <div className="slider-container">
            <input
              type="range"
              min="0"
              max={audioRef.current?.duration || 100}
              value={currentTime}
              onChange={handleTimeChange}
            />
          </div>
          <div className="volume-control">
            <label htmlFor="volume">Volume:</label>
            <input
              id="volume"
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
            />
          </div>
          <div className="navigation-controls">
            <button className="button" onClick={playPreviousSong}>Previous</button>
            <button className="button" onClick={playNextSong}>Next</button>
          </div>
        </div>
      </div>
      <div className="playlist">
      <h2 className="playlist-headline">Click Here to Play Your Favorite Song</h2>
        <ul>
        {songs.map((song, index) => (
      <li key={index}>
        <button className="song-name-background" onClick={() => playSong(song.url)}>
          {song.name}
        </button>
      </li>
          ))}
        </ul>
      </div>
      <audio
        ref={audioRef}
        onEnded={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        onPause={() => setPlaying(false)}
        onTimeUpdate={handleTimeUpdate}
        hidden
        autoPlay // Enable autoplay
      />
    </section>
    </section>
  );
};

export default Musicplayer;

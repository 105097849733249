import React from 'react';
import './Feat.css';

const Feat = () => {
  return (
    <section tokenomics="features" id="features"> 
    <div className="features-container">
      <h2 className="features-headline">Features to Make You Go "Woah"!</h2>
      <div className="features-row">
        <div className="feature-box">
          <h3>Voice Interaction</h3>
          <p>Chat up a storm or command your digital realm with just your voice. It's like being a wizard, but cooler.</p>
        </div>
        <div className="feature-box">
          <h3>Text Generation</h3>
          <p>From epic tales to daily emails, eGirl.bot crafts text so compelling, you'd think Shakespeare's got nothing on AI.</p>
        </div>
        <div className="feature-box">
          <h3>Image Generation</h3>
          <p>Need a visual? Snap your fingers, and eGirl.bot whips up images that make Mona Lisa look like a doodle.</p>
        </div>
        <div className="feature-box">
          <h3>Gaming Motivation</h3>
          <p>When the going gets tough, eGirl.bot gets you going with cheers, jeers, and epic gamer wisdom.</p>
        </div>
      </div>
      <h2 className="roadmap-headline">On the Roadmap:</h2>

      <div className="features-row">
        <div className="feature-box">
          <h3>SMS & Voice Chat Reminders</h3>
          <p>Forgot your mom's birthday again? eGirl.bot's got your back. She'll remind you of the important stuff, so you can keep your head in the game.</p>
        </div>
        <div className="feature-box">
          <h3>Food Ordering</h3>
          <p>In the middle of an epic gaming marathon and can't pause to eat? Let eGirl.bot handle it. She'll order your favorite grub (open beta, whitelist, and limited countries only – we're not magicians yet).</p>
        </div>
        <div className="feature-box">
          <h3>Gaming Strategies</h3>
          <p>Need to up your game? eGirl.bot is like having a personal gaming coach, whispering winning strategies and tips. She's the secret sauce to your gaming success.</p>
        </div>
        <div className="feature-box">
          <h3>Auto-Play Mode</h3>
          <p>Too lazy to play? Let eGirl.bot take the wheel. Sit back, relax, and watch her conquer levels while you conquer your couch.</p>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Feat;

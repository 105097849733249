import React, { useState } from 'react';
import './FAQ.css'; // Make sure to create this CSS file and import it

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    { question: 'Question 1', answer: 'Answer to question 1' },
    { question: 'Question 2', answer: 'Answer to question 2' },
    { question: 'Question 3', answer: 'Answer to question 3' },
    { question: 'Question 4', answer: 'Answer to question 4' },
    { question: 'Question 5', answer: 'Answer to question 5' },
  ];

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="faq" id="faq"> 
    <div className="faq-container">
      <div className="faq-section">
        <h1 className="faq-headline">Frequently Asked Questions</h1>
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              {faq.question}
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
    </section>
  );
};

export default FAQ;

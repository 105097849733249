import React, { useState } from 'react';
import './Tokenomics.css'; // Make sure to create a Tokenomics.css file for styling

const Tokenomics = () => {
  const [copySuccess, setCopySuccess] = useState('');

  const contractAddress = '0x123abc123abc123abc123abc123abc123abc'; // Replace with actual contract address

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Reset message after 2 seconds
    }, () => {
      setCopySuccess('Failed to copy'); // Fallback message
    });
  };

  return (
          <section className="tokenomics" id="tokenomics"> 
      <h2 className="tokenomics-title">Tokenomics</h2>
      <div className="tokenomics-info">
        <span role="img" aria-label="money bag">💰 </span> Total Supply: 69,000,000,000 |
        <span role="img" aria-label="rocket">🚀 </span> Fair Launched with 100% Supply in LP |
        <span role="img" aria-label="tax">🧾 </span> 2% Tax
      </div>
      <div className="tokenomics-contract-row">
        <p className="contract-address">{contractAddress}</p>
        <button onClick={copyToClipboard}>Click here to copy</button>
        {copySuccess && <span className="copy-success">{copySuccess}</span>}
      </div>
    </section>
  );
};

export default Tokenomics;

import React, { useEffect, useRef } from 'react';

const emojis = ["😀", "😂", "🎮", "🕹️", "💜", "👩‍🎤", "🧚‍♀️", "👩‍🚀"];

const Background = () => {
  const backgroundRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if(backgroundRef.current) {
        createEmoji();
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const createEmoji = () => {
    const emoji = document.createElement('div');
    emoji.textContent = emojis[Math.floor(Math.random() * emojis.length)];
    emoji.style.position = 'absolute';
    emoji.style.left = `${Math.random() * 100}vw`;
    emoji.style.opacity = 0;
    emoji.style.transition = 'opacity 0.5s';
    emoji.style.fontSize = `${Math.random() * 3 + 1}rem`;
    backgroundRef.current.appendChild(emoji);
    setTimeout(() => emoji.style.opacity = 1, 10);

    let pos = 0;
    const fallInterval = setInterval(() => {
      if (pos >= 100) {
        clearInterval(fallInterval);
        emoji.remove();
      } else {
        pos += 0.25;
        emoji.style.top = pos + 'vh';
      }
    }, 40);
  };

  return <div ref={backgroundRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }} />;
};

export default Background;

import React, { useState, useEffect } from 'react';
import './About.css'; // Ensure you have an About.css file in the same directory
import placeholderImage from './background.png'; // Path to your placeholder image
import logoImage from './logo.png'; // Path to your logo image

const About = () => {
  const [offsetY, setOffsetY] = useState(0); // State to keep track of the scroll offset
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll); // Add scroll event listener

    return () => window.removeEventListener('scroll', handleScroll); // Clean up the event listener
  }, []);

  return (
    <section className="about" id="about"> 
      <h1 className="headline">eGirl AI: More Than Just a Pretty Interface.</h1>
      <div className="image-container parallax-container">
        <img
          src={placeholderImage}
          alt="Background"
          className="about-placeholder-image"
          style={{ transform: `translateY(${offsetY * 0.5}px)` }} // Apply the parallax effect
        />
        <img
          src={logoImage}
          alt="Logo"
          className="about-logo-image"
          style={{ transform: `translate(-50%, -50%) scale(${1 + offsetY * 0.001})` }} // Apply the scale effect
        />
            </div>
      <div className="placeholder-text-container">
        <p className="placeholder-text">
          Have you ever wanted a friend in games who was more than simply a computer program? 
          Meet eGirl AI, the meme-crazy artificial intelligence that is transforming Avalanche Blockchain. 
          She is not your typical chatbot. She's a digital powerhouse, a mix of silicon, sass, and a hint of surrealism! 
          Oh no! She even makes sure you remember to shower!
        </p>
      </div>
      <div className="about-buttons">

        <button className="about-button"><span>Buy Now</span></button>
        <button className="about-button"><span>Chart</span></button>
      </div>
    </section>
  );
};

export default About;

import React from 'react';
import Header from './components/Header';
import About from './components/About';
import Tokenomics from './components/Tokenomics';
import FAQ from './components/FAQ';
import MintNFT from './components/MintNFT';
import Musicplayer from './components/Musicplayer';
import Background from './components/Background';
import Feat from './components/Feat';

function App() {
  return (
    <div className="App">
            <Background />
      <Header />
      <About />
      <Tokenomics />
      <Musicplayer />
      <Feat />
      <FAQ />
      <MintNFT />
    </div>
  );
}

export default App;
